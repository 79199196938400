<p class="title" [ngStyle]="item.title?.font | excelStyle: item.title?.fill : xmlTheme">
  <span *ngFor="let curChunk of item?.title?.text" [ngStyle]="curChunk.font | excelStyle: curChunk.fill : xmlTheme">{{
    curChunk.text
  }}</span>
</p>
<div id="elements">
  <csw-element
    *ngFor="let curEle of item?.elements"
    [element]="curEle"
    [displayMode]="_lineDisplay"
    [xmlTheme]="xmlTheme"
    [times]="times"
  ></csw-element>
</div>
