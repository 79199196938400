<mat-expansion-panel [expanded]="expand" *ngIf="data">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h3 [ngStyle]="data.title?.font | excelStyle: data.title?.fill : xmlTheme">
        <span
          *ngFor="let curChunk of data.title?.text"
          [ngStyle]="curChunk.font | excelStyle: curChunk.fill : xmlTheme"
        >
          {{ curChunk.text }}
        </span>
      </h3>
      <p [ngStyle]="data.subtitle?.font | excelStyle: data.subtitle?.fill : xmlTheme">
        <span
          *ngFor="let curChunk of data.subtitle?.text"
          [ngStyle]="curChunk.font | excelStyle: curChunk.fill : xmlTheme"
          >{{ curChunk.text }}</span
        >
      </p>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-expansion-panel-body>
    <csw-times-row [times]="times"></csw-times-row>
    <csw-line-item
      *ngFor="let curItem of data.items"
      [item]="curItem"
      [xmlTheme]="xmlTheme"
      [times]="times"
    ></csw-line-item>
  </mat-expansion-panel-body>
</mat-expansion-panel>
