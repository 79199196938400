import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { CSElement } from '../models/coversheet/cselement.model';
import { CSPart } from '../models/coversheet/cspart.model';
import { LineDisplay } from '../models/lineDisplay.enum';

@Component({
  selector: 'csw-element',
  templateUrl: './cs-element.component.html',
  styleUrls: ['./cs-element.component.scss'],
})
export class CsElementComponent implements OnInit {
  parts: CSPart[] = [];

  @HostBinding('class.chaos')
  isChaos: boolean;

  @HostBinding('style.grid-template-columns')
  gridTemplateColumns: string = 'repeat(2, 1fr)';

  @Input() xmlTheme: Document;
  @Input()
  set element(ele: CSElement) {
    this.parts = ele.parts;
  }

  @Input()
  set displayMode(display: LineDisplay) {
    this.isChaos = display === LineDisplay.CHAOS;
  }

  @Input() times: string[];

  ngOnInit(): void {
    this.updateGridColumns();
  }

  private updateGridColumns(): void {
    if ((this.times?.length ?? 0) > 0) {
      this.gridTemplateColumns = `repeat(${this.times?.length}, 1fr)`;
    }
  }
}
