import { Component, HostBinding, Input } from '@angular/core';

import { CSLineItem } from '../models/coversheet/cslineitem.model';
import { LineDisplay } from '../models/lineDisplay.enum';
import { Location } from '../models/location.enum';

@Component({
  selector: 'csw-line-item',
  templateUrl: './cs-line-item.component.html',
  styleUrls: ['./cs-line-item.component.scss'],
})
export class CsLineItemComponent {
  displays = LineDisplay;

  @HostBinding('class.paged')
  isPaged = false;
  @HostBinding('class.chaos')
  isChaos = false;
  @HostBinding('class.lancaster')
  isLancaster = false;

  @Input() xmlTheme: Document;
  @Input()
  item: CSLineItem;
  _lineDisplay: LineDisplay = LineDisplay.NORMAL;

  @Input()
  set lineDisplay(display: LineDisplay) {
    this._lineDisplay = display;
    this.isPaged = display === LineDisplay.PAGED;
    this.isChaos = display === LineDisplay.CHAOS;
  }

  @Input()
  set location(location: Location) {
    this.isLancaster = location === Location.LANCASTER;
  }

  @Input() times: string[];
}
